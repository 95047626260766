<template>
  <div>
    <v-tabs
      v-model="tab"
      background-color="primary"
      dark
      centered
      icons-and-text
    >
      <v-tabs-slider />

      <v-tab href="#tab-informations">
        {{ $t('messages.views.admin.components.configuration.stations.window.stationWindow.informations') }}
        <v-icon>mdi-information</v-icon>
      </v-tab>

      <v-tab href="#tab-crossroads">
        {{ $t('messages.views.admin.components.configuration.stations.window.stationWindow.crossroads') }}
        <v-icon>mdi-traffic-light</v-icon>
      </v-tab>

      <v-tab href="#tab-sensors">
        {{ $t('messages.views.admin.components.configuration.stations.window.stationWindow.sensors') }}
        <v-icon>mdi-radar</v-icon>
      </v-tab>

      <v-tab href="#tab-counters">
        {{ $t('messages.views.admin.components.configuration.stations.window.stationWindow.counters') }}
        <v-icon>mdi-counter</v-icon>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item
        value="tab-informations"
      >
        <v-row>
          <v-col>
            <v-simple-table dense>
              <tbody>
                <tr>
                  <td>{{ $t('labels.station.synchronization') }} : </td>
                  <td>
                    <o-chip-station-synchronization
                      class="my-2"
                      :synchronization="station.synchronization"
                      @click="station.synchronization === 'SYNCHRONIZATION_PENDING' ? showConfiguration() : null"
                    />
                  </td>
                </tr>
                <tr v-if="station.synchronizedAt">
                  <td>{{ $t('labels.station.synchronizedAt') }} : </td>
                  <td>{{ (new Date(station.synchronizedAt)).toLocaleString([], { dateStyle: 'short', timeStyle: 'medium' }) }}</td>
                </tr>
                <tr>
                  <td>{{ $t('labels.station.stationWatcherName') }} : </td>
                  <td>{{ station.stationWatcherName }}</td>
                </tr>
                <tr>
                  <td>{{ $t('labels.station.stationWatcherVersion') }} : </td>
                  <td>{{ station.stationWatcherVersion }}</td>
                </tr>
                <tr v-if="station.name">
                  <td>{{ $t('labels.station.name') }} : </td>
                  <td>{{ station.name }}</td>
                </tr>
                <tr v-if="station.parameterVersion">
                  <td>{{ $t('labels.station.parameterVersion') }} : </td>
                  <td>{{ station.parameterVersion }}</td>
                </tr>
                <tr v-if="station.softwareVersion">
                  <td>{{ $t('labels.station.softwareVersion') }} : </td>
                  <td>{{ station.softwareVersion }}</td>
                </tr>
                <tr v-if="station.brand">
                  <td>{{ $t('labels.station.brand') }} : </td>
                  <td>{{ station.brand }}</td>
                </tr>
                <tr v-if="station.model">
                  <td>{{ $t('labels.station.model') }} : </td>
                  <td>{{ station.model }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item
        value="tab-crossroads"
      >
        <v-list v-if="station.crossroads.length">
          <v-list-item
            v-for="(crossroad, i) in station.crossroads"
            :key="`crossroad-${i}`"
            @click="() => $emit('crossroad-click', crossroad)"
          >
            <v-list-item-icon>
              <v-icon>
                {{ 'mdi-alpha-' + crossroad.letter.toLowerCase() + '-circle-outline' }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="d-flex">
                <span>{{ getCrossroadName(crossroad, station) }}</span>
                <v-spacer />
                <v-chip
                  v-if="crossroad.location === null"
                  color="warning"
                  class="mr-2"
                  small
                >
                  {{ $t('messages.views.admin.components.configuration.stations.window.stationWindow.noPositionForCrossroad') }}
                </v-chip>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <p
          v-else
          class="ml-5 text--secondary font-italic"
        >
          {{ $t('messages.views.admin.components.configuration.stations.window.stationWindow.noCrossroad') }}
        </p>
      </v-tab-item>

      <v-tab-item
        value="tab-sensors"
      >
        <v-data-table
          :headers="sensors.headers"
          :items="station.sensors"
          :loading="sensors.loading"
          disable-filtering
          disable-pagination
          disable-sort
          fixed-header
          hide-default-footer
          :no-data-text="$t('messages.views.admin.components.configuration.stations.window.stationWindow.noSensor')"
          dense
          sort-by="inputNumber"
          :items-per-page="-1"
        >
          <template v-slot:header.actions>
            <v-btn
              icon
              @click="$store.commit('map/changeMode', { mode: 'create_sensor' })"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>

          <template v-slot:item.type="props">
            {{ $t('constants.sensor.type.' + props.item.type) }}
          </template>

          <template v-slot:item.actions="props">
            <o-action-menu
              :actions="[
                {
                  icon: 'mdi-pencil',
                  label: 'Modifier',
                  listeners: {
                    click: () => editSensor(props.item.id),
                  },
                },
                {
                  icon: 'mdi-delete',
                  label: 'Supprimer',
                  color: 'red',
                  confirm: {
                    callback: () => deleteSensor(props.item.id),
                    title: 'messages.views.admin.components.configuration.stations.window.stationWindow.modal.deleteSensor.title',
                    text: 'messages.views.admin.components.configuration.stations.window.stationWindow.modal.deleteSensor.text',
                    color: 'red',
                  },
                },
              ]"
            />
          </template>
        </v-data-table>
      </v-tab-item>

      <v-tab-item
        value="tab-counters"
      >
        <v-data-table
          :headers="counters.headers"
          :items="station.counters"
          :loading="counters.loading"
          disable-filtering
          disable-pagination
          fixed-header
          hide-default-footer
          :no-data-text="$t('messages.views.admin.components.configuration.stations.window.stationWindow.noCounter')"
          dense
          sort-by="number"
          :items-per-page="-1"
          :item-class="(item) => ({
            'font-italic grey--text': isCounterSystem(item.id),
          })"
        >
          <template v-slot:header.actions>
            <v-btn
              icon
              @click="createCounter"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>

          <template v-slot:item.type="props">
            {{ $t('constants.counter.type.' + props.item.type) }}
          </template>

          <template v-slot:item.sensors="props">
            <span class="text-truncate">
              {{ station.sensors.find((sensor) => sensor.id === props.item.sensors[0]).name }}
            </span>
            <span v-if="props.item.sensors.length > 1">, ...</span>
          </template>

          <template v-slot:item.actions="props">
            <o-action-menu
              v-if="!isCounterSystem(props.item.id)"
              :actions="[
                {
                  icon: 'mdi-pencil',
                  label: 'Modifier',
                  listeners: {
                    click: () => editCounter(props.item.id),
                  },
                },
                {
                  icon: 'mdi-delete',
                  label: 'Supprimer',
                  color: 'red',
                  confirm: {
                    callback: () => deleteCounter(props.item.id),
                    title: 'messages.views.admin.components.configuration.stations.window.stationWindow.modal.deleteCounter.title',
                    text: 'messages.views.admin.components.configuration.stations.window.stationWindow.modal.deleteCounter.text',
                    color: 'red',
                  },
                },
              ]"
            />
          </template>
        </v-data-table>

        <div
          v-if="station.counters.length"
          class="text-caption font-italic grey--text mt-2"
        >
          * {{ $t('messages.views.admin.components.configuration.stations.window.stationWindow.countersHandledBySystem') }}
        </div>
      </v-tab-item>
    </v-tabs-items>

    <v-dialog
      v-model="jsonComparison.show"
      max-width="1000"
    >
      <v-card :loading="jsonComparison.loading">
        <v-toolbar
          color="primary"
          dark
        >
          {{ $t('messages.views.admin.components.configuration.stations.window.stationWindow.pendingConfigurationForStation', {
            name: station.stationWatcherName,
          }) }}
          <v-spacer />
          <v-btn
            icon
            dark
            @click="jsonComparison.show = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text
          class="pa-5"
          style="max-height: 500px; overflow: auto;"
        >
          <vue-json-compare
            :old-data="jsonComparison.oldData"
            :new-data="jsonComparison.newData"
          />
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            color="success"
            @click="acceptStationConfiguration"
          >
            {{ $t('actions.validate') }}
          </v-btn>

          <v-btn
            @click="jsonComparison.show = false"
          >
            {{ $t('actions.cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <sensor-form-modal
      v-if="modal.sensor.show"
      v-model="modal.sensor.show"
      :sensor="modal.sensor.model"
      :used-input-numbers="usedInputNumbersForSensorFormModal"
      @submit="saveSensor"
    />

    <counter-form-modal
      v-if="modal.counter.show"
      v-model="modal.counter.show"
      :counter="modal.counter.model"
      :sensors="station.sensors"
      :used-numbers="usedNumbersForCounterFormModal"
      @submit="saveCounter"
    />
  </div>
</template>

<script>
  import vueJsonCompare from 'vue-json-compare';
  import { getCrossroadName } from '@utils/crossroad';

  export default {
    components: {
      SensorFormModal: () => import('@/views/admin/components/configuration/stations/window/stationWindow/SensorFormModal'),
      CounterFormModal: () => import('@/views/admin/components/configuration/stations/window/stationWindow/CounterFormModal'),
      VueJsonCompare: vueJsonCompare,
    },

    props: {
      station: {
        type: Object,
        required: true,
      },
    },

    data: function () {
      return {
        tab: 0,
        jsonComparison: {
          show: false,
          loading: false,
          oldData: {},
          newData: {},
        },
        sensors: {
          loading: false,
          headers: [
            {
              text: this.$t('labels.sensor.inputNumber'),
              value: 'inputNumber',
              sortable: true,
              align: 'center',
            },
            {
              text: this.$t('labels.sensor.name'),
              value: 'name',
              sortable: true,
            },
            {
              text: this.$t('labels.sensor.type'),
              value: 'type',
              sortable: false,
              align: 'center',
            },
            {
              text: '',
              value: 'actions',
              sortable: false,
              align: 'center',
            },
          ],
        },
        counters: {
          loading: false,
          headers: [
            {
              text: this.$t('labels.counter.number'),
              value: 'number',
              sortable: false,
              align: 'center',
            },
            {
              text: this.$t('labels.counter.type'),
              value: 'type',
              sortable: false,
              align: 'center',
            },
            {
              text: this.$t('labels.counter.sensors'),
              value: 'sensors',
              sortable: false,
              align: 'center',
            },
            {
              text: '',
              value: 'actions',
              sortable: false,
              align: 'center',
            },
          ],
        },
        modal: {
          counter: {
            show: false,
            model: null,
          },
          sensor: {
            show: false,
            model: null,
            location: null,
          },
        },
      };
    },

    computed: {
      usedInputNumbersForSensorFormModal () {
        const numbers = this.station.sensors.map((sensor) => sensor.inputNumber);

        if (this.modal.sensor.model !== null) {
          numbers.splice(numbers.indexOf(this.modal.sensor.model.inputNumber), 1);
        }

        return numbers;
      },
      usedNumbersForCounterFormModal () {
        const numbers = this.station.counters.map((counter) => counter.number);

        if (this.modal.counter.model !== null) {
          numbers.splice(numbers.indexOf(this.modal.counter.model.number), 1);
        }

        return numbers;
      },
    },

    watch: {
      tab (newValue) {
        this.$emit('switch-tab', newValue.replace('tab-', ''));
      },
    },

    methods: {
      isCounterSystem (id) {
        const counter = this.station.counters.find((counter) => counter.id === id);
        if (counter) {
          return counter.system;
        }

        throw new Error('Counter not found');
      },

      showConfiguration () {
        this.$apollo.query({
          query: require('@gql/queries/station/getPendingStationConfiguration.gql'),
          client: 'stations',
          fetchPolicy: 'no-cache',
          variables: {
            id: this.station.id,
          },
        }).then((result) => {
          this.jsonComparison.newData = result.data.newData;
          this.jsonComparison.oldData = result.data.oldData;
          this.jsonComparison.show = true;
        }).catch((e) => {
          this.$flash(null, 'error');
        });
      },

      acceptStationConfiguration () {
        this.jsonComparison.loading = true;
        this.$apollo.mutate({
          mutation: require('@gql/mutations/station/acceptStationConfiguration.gql'),
          client: 'stations',
          fetchPolicy: 'no-cache',
          variables: {
            input: {
              station: this.station.id,
            },
          },
        }).then((result) => {
          this.$flash('messages.views.admin.components.configuration.stations.window.stationWindow.acceptStationConfiguration.success', 'success');
        }).catch((e) => {
          this.$flash('messages.views.admin.components.configuration.stations.window.stationWindow.acceptStationConfiguration.error', 'error');
        }).finally(() => {
          this.$emit('station-reconfigured');
          this.jsonComparison.show = false;
          this.jsonComparison.loading = false;
        });
      },

      getCrossroadName (crossroad, station) {
        return getCrossroadName(crossroad, station);
      },

      createSensor (location) {
        this.modal.sensor.location = location;
        this.modal.sensor.model = null;
        this.modal.sensor.show = true;
      },

      editSensor (sensorId) {
        this.modal.sensor.model = this.station.sensors.find((sensor) => sensor.id === sensorId);
        this.modal.sensor.show = true;
      },

      deleteSensor (sensorId) {
        this.sensors.loading = true;

        this.$apollo.mutate({
          mutation: require('@gql/mutations/sensor/deleteSensor.gql'),
          client: 'stations',
          fetchPolicy: 'no-cache',
          variables: {
            id: sensorId,
          },
        }).then((e) => {
          this.$emit('sensor-deleted');
        }).catch((e) => {
          this.$flash(null, 'error');
        }).finally(() => {
          this.sensors.loading = false;
        });
      },

      saveSensor (data) {
        this.sensors.loading = true;
        this.modal.sensor.show = false;

        const sensor = {};
        const input = {};
        let mutation;
        if (this.modal.sensor.model === null) {
          input.station = this.station.id;
          sensor.location = this.modal.sensor.location;
          mutation = require('@gql/mutations/sensor/createSensor.gql');
        } else {
          sensor.id = this.modal.sensor.model.id;
          sensor.location = this.modal.sensor.model.location;
          mutation = require('@gql/mutations/sensor/updateSensor.gql');
        }

        sensor.name = data.name;
        sensor.description = data.description;
        sensor.inputNumber = data.inputNumber;
        sensor.type = data.type;

        this.$apollo.mutate({
          mutation: mutation,
          client: 'stations',
          fetchPolicy: 'no-cache',
          variables: {
            input: {
              ...sensor,
              ...input,
            },
          },
        }).then((response) => {
          if (this.modal.sensor.model === null) {
            sensor.id = response.data.createSensor.id;
            this.station.sensors.push(sensor);
            this.$emit('sensor-created', sensor);
          } else {
            const index = this.station.sensors.findIndex((s) => s.id === sensor.id);
            this.station.sensors.splice(index, 1, sensor);
            this.$emit('sensor-updated', sensor);
          }
        }).catch((e) => {
          this.$flash(null, 'error');
        }).finally(() => {
          this.sensors.loading = false;
        });
      },

      createCounter () {
        this.modal.counter.model = null;
        this.modal.counter.show = true;
      },

      editCounter (counterId) {
        this.modal.counter.model = this.station.counters.find((counter) => counter.id === counterId);
        this.modal.counter.show = true;
      },

      deleteCounter (counterId) {
        this.counters.loading = true;
        const index = this.station.counters.findIndex((counter) => counter.id === counterId);
        this.station.counters.splice(index, 1);

        this.$apollo.mutate({
          mutation: require('@gql/mutations/counter/deleteCounter.gql'),
          client: 'stations',
          fetchPolicy: 'no-cache',
          variables: {
            id: counterId,
          },
        }).catch((e) => {
          this.$flash(null, 'error');
        }).finally(() => {
          this.counters.loading = false;
        });
      },

      saveCounter (data) {
        this.counters.loading = true;
        this.modal.counter.show = false;

        const counter = {};
        const input = {};
        let mutation;
        if (this.modal.counter.model === null) {
          input.station = this.station.id;
          mutation = require('@gql/mutations/counter/createCounter.gql');
        } else {
          counter.id = this.modal.counter.model.id;
          mutation = require('@gql/mutations/counter/updateCounter.gql');
        }

        counter.type = data.type;
        counter.sensors = data.sensors;

        this.$apollo.mutate({
          mutation: mutation,
          client: 'stations',
          fetchPolicy: 'no-cache',
          variables: {
            input: {
              ...counter,
              ...input,
            },
          },
        }).then((response) => {
          if (this.modal.counter.model === null) {
            counter.id = response.data.createCounter.id;
            counter.number = response.data.createCounter.number;
            this.station.counters.push(counter);
          } else {
            const index = this.station.counters.findIndex((c) => c.id === counter.id);
            counter.id = response.data.updateCounter.id;
            counter.number = response.data.updateCounter.number;
            this.station.counters.splice(index, 1, counter);
          }
        }).catch((e) => {
          if (e.graphQLErrors !== undefined && e.graphQLErrors[0] !== undefined && e.graphQLErrors[0].extensions.code === 'COUNTER_ALREADY_DEFINED') {
            this.$flash('messages.views.admin.components.configuration.stations.window.stationWindow.counterAlreadyDefined', 'error');
          } else {
            this.$flash(null, 'error');
          }
        }).finally(() => {
          this.counters.loading = false;
        });
      },

      onSensorCreate (location) {
        this.createSensor(location);
      },
    },
  };
</script>
